.myBackgroud1 {
  background: #0dcaf0 !important;
  font-weight: bold;
}
.myBackgroud2 {
  background: #b3f2ff !important;
  font-weight: bold;
}
.myBackgroud3 {
  background: #d7f6fc !important;
  font-weight: bold;
}
